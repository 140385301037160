import {ModuleOptions} from "vuex";

export default {
    namespaced: true,
    state: {
        practice: null
    },
    mutations: {
        setPractice(state, practice: number) {
            state.practice = practice
        },
        getPractice(state) {
            return state.practice
        }
    },
    actions: {
        setPractice({commit}, practice: number) {
            commit('setPractice', practice)
        },
        getPractice({state}) {
            return state.practice
        }
    }
} as ModuleOptions
