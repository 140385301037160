import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class reloadTables extends VuexModule {
    reload = false;

    @Mutation
    SET_RELOAD(reload: boolean) {
        this.reload = reload;
    }

    @Action({ commit: 'SET_RELOAD' })
    setLoading(reload: boolean) {
        return reload;
    }
}
