import {Ability, AbilityBuilder} from '@casl/ability'

// Alternatively this data can be retrieved from server
export default function defineAbilitiesFor(user) {

    type Actions = 'Create' | 'View' | 'Update' | 'Delete' | 'manage';
    type Subjects =
        'all'
        | 'Auth'
        | 'Scrubber'
        | 'Practice'
        | 'BillingRules'
        | 'Insurance'
        | 'ProcedureCode'
        | 'ProcedureType'
        | 'FeeSchedule'
        | 'ListOptions'
        | 'Users'
        | 'ScrubberProcedureSettings';
    type AppAbility = Ability<[Actions, Subjects]>;

    const {can, rules , cannot} = new AbilityBuilder<AppAbility>();

    console.log("triggerd abilities", user);

    if (!user) {
        can('View', 'Auth')
    } else {

        can('View', 'Auth')
        user.abilitys?.forEach((ability) => {
            can(ability.actions as Actions[], ability.resource as Subjects);
        });

        // if (user.role === 'User') {
        //
        //     can('View', ['Auth', 'Scrubber', 'Practice', 'Insurance', 'ProcedureCode', 'ProcedureType', 'FeeSchedule', 'ListOptions'])
        //     can('update' ,'Practice')
        //     can('create' ,'Practice')
        //     cannot('manage', 'ScrubberProcedureSettings')
        //
        // } else if (user.role === 'Admin') {
        //     can('manage', 'all')
        // }

    }

    return new Ability(rules);
}
