import {Route, RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
//let change to patient

            path: '/patients',
            name: 'patients',
            component: () => import('@/class-components/Patients/PatientList.vue'),
            meta: {
                resource: 'Patients',
                action: 'View',
                pageTitle: 'Patients',
                breadcrumb: [
                    {
                        text: 'Patients',
                        active: true,
                    },
                ],
            },

        },
        {
            path: '/patient-details/:id',
            name: 'patient-details',
            component: () => import('@/class-components/Patients/PatientTabs.vue'),
            meta: {
                resource: 'Patients',
                action: 'View',
                pageTitle: 'Patient Details',
                breadcrumb: [
                    {
                        text: 'Patients',
                        to: '/patients',
                    },
                    {
                        text: 'Patient Details',
                        active: true,
                    },
                ],
            },
        }
    ];
    return routes;
}
