import {RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/fee-schedules',
            name: 'fee-schedules',
            component: () => import('@/views/fee-schedules/FeeSchedules.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: 'Fee Schedules',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/fee-schedules/:feeScheduleId',
            name: 'fee-schedules-view-one',            
            component: () => import('@/views/fee-schedules/FeeSchedulesViewOne.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'View Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedules/:type/add/:feeScheduleId',
            name: 'fee-schedules-add',
            component: () => import('@/views/fee-schedules/FeeSchedulesAdd.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Add Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedule-version/edit/:feeScheduleVersionId',
            name: 'fee-schedules-version-edit',
            component: () => import('@/class-components/fee-schedules/fee-schedules-more/FeeScheduleVersionEdit.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Add Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedules/edit/:feeScheduleId',
            name: 'fee-schedules-edit',
            component: () => import('@/views/fee-schedules/FeeSchedulesEdit.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Edit Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedules-manual-start',
            name: 'fee-schedules-manual-start',
            component: () => import('@/class-components/fee-schedules/fee-schedules-more/manual-starting-point/ChooseStartingPoint.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Edit Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedules-manual-clone',
            name: 'fee-schedules-manual-clone',
            component: () => import('@/class-components/fee-schedules/fee-schedules-more/manual-starting-point/CloneStartingPoint.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Edit Fee Schedule',
                        active: true
                    },
                ],
            },
        },
        {
            path: '/fee-schedules-clone-form/:type/add/:feeScheduleId',
            name: 'fee-schedules-clone-form',
            component: () => import('@/class-components/fee-schedules/fee-schedules-more/manual-starting-point/CloneForm.vue'),
            meta: {
                resource: 'FeeSchedule',
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Fee Schedules',
                        active: false,
                        to: '/fee-schedules',
                    },
                    {
                        text: 'Edit Fee Schedule',
                        active: true
                    },
                ],
            },
        }
    ]

    return routes;
}
